<script>
import {
  notificationMethods,
} from "@/state/helpers";
import { authUserMethods } from  "../../../state/helpers/authuser"
import { required, email } from "vuelidate/lib/validators";
import { mapState } from "vuex";
import ownerIcon from '../../../assets/images/small/house-owner.png'
import tenantIcon from '../../../assets/images/small/house.png'
import appConfig from "@/app.config";

/**
 * Register component
 */
export default {
  page: {
    title: "Register",
    meta: [{ name: "description", content: appConfig.description }],
  },
  data() {
    return {
      user: {
        first_name: '',
        last_name: '', 
        email: '',
        address: '',
        city: '',
        phone: '',
        zip_code: '',
        state: '',
        password: '',
        confirm_password: '',
      },
      ownerIcon,
      tenantIcon,
      submitted: false,
      regError: null,
      tryingToRegister: false,
      isRegisterError: false,
      registerSuccess: false,
      select_user: null,
    };
  },
  validations: {
    user: {
      first_name: {
        required,
      },
      last_name: {
        required,
      },
      email: {
        required,
        email,
      },
      phone: {
        required,
      },
      password: {
        required,
      },
      confirm_password: {
        required,
      },
      address: {
        required,
      },
      city: {
        required,
      },
      state: {
        required,
      },
      zip_code: {
        required,
      },
    },
  },
  methods: {
    ...authUserMethods,
    ...notificationMethods,
    // Try to register the user in with the email, username
    // and password they provided.
    tryToRegisterIn() {
      this.submitted = true;

      this.$v.$touch();

      if (this.$v.$invalid) {
        return;
      } else {
        if(this.select_user === 'Tenant') {
          this.registerTenant(this.user).then(() => {
            this.select_user = null
          });
        } else if(this.select_user === 'Property Owner') {
          this.registerPropertyOwner(this.user).then(() => {
            this.select_user = null
          });
        }
        
        } 
  
    },
    enableRegisterForm(type) {
       this.select_user = type
    }
  },

  computed: {
    ...mapState("authfack", ["status"]),
    notification() {
      return this.$store ? this.$store.state.notification : null;
    },
  },
};
</script>

<template>
  <div class="account-pages my-5 pt-5">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-md-8 col-lg-6 col-xl-6">
          <div class="card overflow-hidden">
            <div class="bg-primary">
              <div class="text-primary text-center p-4">
                <h5 class="text-white font-size-20">Safe and Sound Real Estate</h5>
                <p class="text-white-50">Create your {{select_user}} account now.</p>
                <a href="/" class="logo logo-admin">
                  <!-- <img
                    src="@/assets/images/logo-sm.png"
                    height="24"
                    alt="logo"
                  /> -->
                  SSRE
                </a>
              </div>
            </div>

            <div class="card-body p-4">
              <div class="p-3">
                <b-alert
                  v-model="registerSuccess"
                  class="mt-3"
                  variant="success"
                  dismissible
                  >Registration successfull.</b-alert
                >

                <b-alert
                  v-model="isRegisterError"
                  class="mt-3"
                  variant="danger"
                  dismissible
                  >{{ regError }}</b-alert
                >

                <div
                  v-if="notification.message"
                  :class="'alert ' + notification.type"
                >
                  {{ notification.message }}
                </div>

                <div class="user_selected_box mt-5" v-if="select_user === null">
                    <h5 class="text-center">Select User type to create account</h5>

                    <div class="user_select_item_box">
                      <div class="selected_main_box" @click="enableRegisterForm('Property Owner')">
                        <img :src="ownerIcon" alt="owner" />
                        <h6>Property Owner</h6>
                      </div>
                      <div class="selected_main_box" @click="enableRegisterForm('Tenant')">
                        <img :src="tenantIcon" alt="tenant" />
                        <h6>Tenant</h6>
                      </div>
                    </div>
                </div>



                <b-form
                  @submit.prevent="tryToRegisterIn"
                  class="form-horizontal mt-4"
                  v-if="select_user !== null"
                >
                <div class="row">
                  <div class="col-md-6">
                    <b-form-group
                    id="first_name-group"
                    label="First Name"
                    label-for="first_name"
                    label-class="form-label"
                    class="mb-3"
                  >
                    <b-form-input
                      id="first_name"
                      v-model="user.first_name"
                      type="text"
                      placeholder="Enter name"
                      :class="{
                        'is-invalid': submitted && $v.user.first_name.$error,
                      }"
                    ></b-form-input>
                    <div
                      v-if="submitted && !$v.user.first_name.required"
                      class="invalid-feedback"
                    >
                      first name is required.
                    </div>
                  </b-form-group>
                  </div>
                  <div class="col-md-6">
                    <b-form-group
                    id="last-name-group"
                    label="Last Name"
                    label-for="last_name"
                    label-class="form-label"
                    class="mb-3"
                  >
                    <b-form-input
                      id="last_name"
                      v-model="user.last_name"
                      type="text"
                      placeholder="Enter name"
                      :class="{
                        'is-invalid': submitted && $v.user.last_name.$error,
                      }"
                    ></b-form-input>
                    <div
                      v-if="submitted && !$v.user.last_name.required"
                      class="invalid-feedback"
                    >
                      last name is required.
                    </div>
                  </b-form-group>
                  </div>

                  <div class="col-md-12">
                    <b-form-group
                    id="email-group"
                    label="Email"
                    label-for="email"
                    label-class="form-label"
                    class="mb-3"
                  >
                    <b-form-input
                      id="email"
                      v-model="user.email"
                      type="email"
                      placeholder="Enter email"
                      :class="{
                        'is-invalid': submitted && $v.user.email.$error,
                      }"
                    ></b-form-input>
                    <div
                      v-if="submitted && $v.user.email.$error"
                      class="invalid-feedback"
                    >
                      <span v-if="!$v.user.email.required"
                        >Email is required.</span
                      >
                      <span v-if="!$v.user.email.email"
                        >Please enter valid email.</span
                      >
                    </div>
                  </b-form-group>
                  </div>

                  <div class="col-md-12">
                    <b-form-group
                    id="last-name-group"
                    label="Phone"
                    label-for="last_name"
                    label-class="form-label"
                    class="mb-3"
                  >
                    <b-form-input
                      id="phone"
                      v-model="user.phone"
                      type="text"
                      placeholder="Enter phone"
                      :class="{
                        'is-invalid': submitted && $v.user.phone.$error,
                      }"
                    ></b-form-input>
                    <div
                      v-if="submitted && !$v.user.phone.required"
                      class="invalid-feedback"
                    >
                     phone is required.
                    </div>
                  </b-form-group>
                  </div>


                  <div class="col-sm-12 col-md-6">

                    <b-form-group
                    id="password-group"
                    label="Password"
                    label-for="password"
                    label-class="form-label"
                    class="mb-3"
                  >
                    <b-form-input
                      id="password"
                      v-model="user.password"
                      type="password"
                      placeholder="Enter password"
                      :class="{
                        'is-invalid': submitted && $v.user.password.$error,
                      }"
                    ></b-form-input>
                    <div
                      v-if="submitted && !$v.user.password.required"
                      class="invalid-feedback"
                    >
                      Password is required.
                    </div>
                  </b-form-group>

                  </div>

                  <div class="col-sm-12 col-md-6">
                  <b-form-group
                  id="password-group"
                  label="Confirm Password"
                  label-for="confirm_password"
                  label-class="form-label"
                  class="mb-3"
                  >
                  <b-form-input
                    id="password"
                    v-model="user.confirm_password"
                    type="password"
                    placeholder="Enter password"
                    :class="{
                      'is-invalid': submitted && $v.user.confirm_password.$error,
                    }"
                  ></b-form-input>
                  <div
                    v-if="submitted && !$v.user.confirm_password.required"
                    class="invalid-feedback"
                  >
                    Confirm Password is required.
                  </div>
                  </b-form-group>

                  </div>

                  <div class="col-md-12">
                    <b-form-group
                    id="address-group"
                    label="Address"
                    label-for="last_name"
                    label-class="form-label"
                    class="mb-3"
                  >
                    <b-form-input
                      id="address"
                      v-model="user.address"
                      type="text"
                      placeholder="Enter address"
                      :class="{
                        'is-invalid': submitted && $v.user.address.$error,
                      }"
                    ></b-form-input>
                    <div
                      v-if="submitted && !$v.user.address.required"
                      class="invalid-feedback"
                    >
                     address is required.
                    </div>
                  </b-form-group>
                  </div>

                  <div class="col-md-5">
                    <b-form-group
                    id="city-group"
                    label="City"
                    label-for="city"
                    label-class="form-label"
                    class="mb-3"
                  >
                    <b-form-input
                      id="city"
                      v-model="user.city"
                      type="text"
                      placeholder="Enter city"
                      :class="{
                        'is-invalid': submitted && $v.user.city.$error,
                      }"
                    ></b-form-input>
                    <div
                      v-if="submitted && !$v.user.city.required"
                      class="invalid-feedback"
                    >
                    city is required.
                    </div>
                  </b-form-group>
                  </div>

                  <div class="col-md-4">
                    <b-form-group
                    id="state-group"
                    label="State"
                    label-for="state"
                    label-class="form-label"
                    class="mb-3"
                  >
                    <b-form-input
                      id="state"
                      v-model="user.state"
                      type="text"
                      placeholder="Enter state"
                      :class="{
                        'is-invalid': submitted && $v.user.state.$error,
                      }"
                    ></b-form-input>
                    <div
                      v-if="submitted && !$v.user.state.required"
                      class="invalid-feedback"
                    >
                    state is required.
                    </div>
                  </b-form-group>
                  </div>

                  <div class="col-md-3">
                    <b-form-group
                    id="zip_code-group"
                    label="Zip Code"
                    label-for="zip_code"
                    label-class="form-label"
                    class="mb-3"
                  >
                    <b-form-input
                      id="zip_code"
                      v-model="user.zip_code"
                      type="text"
                      placeholder="Enter zip code"
                      :class="{
                        'is-invalid': submitted && $v.user.zip_code.$error,
                      }"
                    ></b-form-input>
                    <div
                      v-if="submitted && !$v.user.zip_code.required"
                      class="invalid-feedback"
                    >
                      zip_code is required.
                    </div>
                  </b-form-group>
                  </div>

                  <!-- <div class="col-sm-12 col-mb-6"></div>
                  <div class="col-sm-12 col-mb-6"></div> -->
                </div>
             

       

     

                  <div class="form-group mb-0 text-center">
                    <div class="col-12 text-end">
                      <b-button type="submit" variant="primary" class="w-md"
                        >Register</b-button
                      >
                    </div>
                  </div>
                  <div class="form-group mt-2 mb-0 row">
                    <div class="col-12 mt-4">
                      <p class="mb-0">
                        By registering you agree to the Sweet Rentals America
                        <a href="#" class="text-primary">Terms of Use</a>
                      </p>
                    </div>
                  </div>
                </b-form>
              </div>
            </div>
            <!-- end card-body -->
          </div>
          <!-- end card -->
          <div class="mt-5 text-center">
            <p>
              Already have an account ?
              <router-link to="/login" class="fw-medium text-primary"
                >Login</router-link
              >
            </p>
            <p>
              ©{{ new Date().getFullYear() }} Safe and Sound Real Estate
              <i class="mdi mdi-heart text-danger"></i>
            </p>
          </div>
        </div>
        <!-- end col -->
      </div>
      <!-- end row -->
    </div>
  </div>
</template>

<style lang="scss" module></style>
